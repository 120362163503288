import React, { useEffect } from 'react'
import { ncpl } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import AboutNCPL from '../assets/images/about-ncpl.png'
import GalleryImg1 from '../assets/images/gallery/villa-at-naranpar-pasayati-kutch.jpg'
import GalleryImg2 from '../assets/images/gallery/bhediya-bet-bsf-1.jpg'
import GalleryImg3 from '../assets/images/gallery/building-constructed-in-ahmedabad-1.jpg'
import GalleryImg4 from '../assets/images/gallery/gallery-image-2.jpg'
import GalleryImg5 from '../assets/images/gallery/jv-business-park-at-bhuj-kutch.jpg'
import GalleryImg6 from '../assets/images/gallery/jv-business-park-at-bhuj-kutch-2.jpg'
import GalleryImg7 from '../assets/images/gallery/shree-sahajanand-gurukul-mankuva-kutch-1.jpg'
import GalleryImg8 from '../assets/images/gallery/shree-sahajanand-gurukul-mankuva-kutch-3.jpg'
import GalleryImg9 from '../assets/images/gallery/shree-swaminarayan-temple-bhuj-kutch-1.jpg'
import GalleryImg10 from '../assets/images/gallery/villa-at-naranpar-pasayati-kutch.jpg'
import GalleryImg11 from '../assets/images/gallery/vishranti-bhuvan-shree-swaminarayan-mandir-bhuj.jpg'
import RmcPlant from '../assets/images/rmc-plant-in-bhuj.png'
import { Container, Col, Row } from 'react-bootstrap'
import Machinery from '../components/Machinery'
import Marquee from "react-fast-marquee";
import Vehicle1 from '../assets/images/vehicle-1.svg'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Slider from "react-slick";
import { benefits } from '../Benefits';
import { Link } from 'react-router-dom'

const Ncpl = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
    };
    return (
        <div>
            <MetaTags>
                <title>{ncpl.title}</title>
                <meta name="description" content={ncpl.description} />
                <link rel="canonical" href={ncpl.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Ready Mixed Concrete</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>NCPL</li>
                    </ul>
                </div>
            </section>
            <section className="entry" id='about'>
                <Container>
                    <Row>
                        <Col md={7} className="my-auto">
                            <h2>About <span>NCPL</span></h2>
                            <div className="content">
                                <p>Currently, we have equipment like Ready Mixed Concrete Batching Plant, portable concrete production machines, mixtures, Concrete pumps, Transit Mixture Trucks, Crain, etc. Also, we are ready to adopt future technologies to improve our strength. We are going to purchase new machinery e.g. Concrete Batching Plant, Pumps, Transit Mixtures Trucks, New centring materials as per requirement of upcoming projects.</p>
                                <p>We are keeping ourselves upgraded with equipments and technologies to complete projects on-time.</p>
                            </div>
                        </Col>
                        <Col md={5} className="position-relative">
                            <img src={AboutNCPL} alt="Ready Mix Concrete" className="img-fluid my-auto bg" />
                        </Col>
                    </Row>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
            </section>
            <section className="section-bg">
                <Container>
                    <h2>Benefits of <span>Ready Mixed Concrete over Site Mixed Concrete</span></h2>
                    <Row>
                        {benefits.map((benefit, index) => {
                            return (
                                <div className="benefits">
                                    <img src={benefit.icon} alt={benefit.name} />
                                    <h4>{benefit.name}</h4>
                                    <p>{benefit.description}</p>
                                </div>
                            )
                        })}
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <div className="text-center">
                        <h2>RMC <span>MACHINERY</span></h2>
                        <h3 className="sub">We Have Heavy Duty Machineries</h3>
                    </div>
                </Container>
                <Machinery />
            </section>
            <section className="section-bg rmc">
                <Container>
                    <Row>
                        <Col md={5} className="position-relative">
                            <img src={RmcPlant} alt="RMC Plant in Bhuj" className="img-fluid my-auto bg" />
                        </Col>
                        <Col md={7} className="my-auto">
                            <h2>RMC <span>Plant in Bhuj</span></h2>
                            <div className="content">
                                <p>We are specialized for our on-time resolution, lowest scrap generation, maximum output and execution of architectural development.</p>
                                <p>We have successfully completed many projects e.g. commercial, residential, temple, trust building, commercial building, healthcare buildings, education structures, pilgrimage sites, etc. We have also completed government structures and many projects under sub-contracting formula. It's time to grow and we are pretty sure about our future because we have strength, dedication and enthusiastic team.</p>
                                <p>That's true if you wish to design your future, you need to work on your efficiency. During the period we've worked on it to achieve many goals.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <div className="text-center">
                        <h2>Photo <span>Gallery</span></h2><br />
                    </div>
                    <Row className='justify-content-center'>
                        <Col md={6}>
                            <Slider {...settings} className='photos'>
                                <div>
                                    <img src={GalleryImg1} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg2} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg3} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg4} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg5} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg6} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg7} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg8} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg9} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg10} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                                <div>
                                    <img src={GalleryImg11} alt="Ready Mix Concrete" className="img-fluid mx-auto" />
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-bg ncpl">
                <Container>
                    <Row>
                        <Col md={9} className="my-auto">
                            <h2>For better future &amp; business, <br /> don't hesitate to contact us...</h2>
                        </Col>
                        <Col md={3} className="text-center my-auto">
                            <Link to="/contact-us" className="btn btn-primary">Contact Us <ArrowRightAltIcon /></Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Ncpl
