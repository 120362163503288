// Core
import React, { useEffect } from 'react'
import { homepage } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import ContactSection from '../components/ContactSection'
import MissionAndVision from '../components/MissionAndVision'
import { Container, Col, Row } from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import About from '../assets/images/about.png'
import AboutNCPL from '../assets/images/about-ncpl.png'
import Vehicle1 from '../assets/images/vehicle-1.svg'
import Image2 from '../assets/images/image-2.png'
import Design1 from '../assets/images/design-1.svg'
import GetInTouch from '../components/GetInTouch'
import SliderProjects from '../components/SliderProjects'
import SliderProjectsOngoing from '../components/SliderProjectsOngoing'
import Marquee from "react-fast-marquee";

const Homepage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{homepage.title}</title>
                <meta name="description" content={homepage.description} />
                <link rel="canonical" href={homepage.canonical} />
            </MetaTags>
            <Slider />
            <section id='about' className="entry section-bg bg-1">
                <Container>
                    <Row>
                        <Col md={6} className='text-center'>
                            <img src={About} alt="" className="img-fluid bg" />
                        </Col>
                        <Col md={6}>
                            <h2>About <span>Narnarayan Construction</span></h2>
                            <div className="content">
                                <p>Narnarayan Construction Private Limited established in 2020, earlier it was known as M/s. Narnarayan Construction (Proprietary/2013), has recently completed Seven years in the construction industry.</p>
                                <p>During the journey, we have successfully completed many projects e.g. commercial, residential, Temple, Trust Building, Commercial Building, Commercial Complex a healthcare buildings, education structures, pilgrimage sites etc. </p>
                                <p>We have also completed government structures and many projects under sub-contracting formula. It's time to grow and we are pretty sure about our future because we have strength, dedication and enthusiastic team.</p>
                            </div>
                            <Link to="/business" className="link-primary">Read more <ArrowRightAltIcon /></Link>
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={6}>
                                <h2>About <span>NCPL</span></h2>
                                <div className="content">
                                    <p>Currently, we have equipment like Ready Mixed Concrete Batching Plant, portable concrete production machines, mixtures, Concrete pumps, Transit Mixture Trucks, Crain, etc. Also, we are ready to adopt future technologies to improve our strength. We are going to purchase new machinery e.g. Concrete Batching Plant, Pumps, Transit Mixtures Trucks, New centring materials as per requirement of upcoming projects.</p>
                                    <p>We are keeping ourselves upgraded with equipments and technologies to complete projects on-time.</p>
                                </div>
                                <Link to="/ncpl" className="link-primary">Read more <ArrowRightAltIcon /></Link>
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={AboutNCPL} alt="About" className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
            </section>
            <section id='projects'>
                <Container>
                    <h2>Our <span>Projects</span></h2><br />
                    <p>Projects that we have completed</p>
                </Container>
                <Container fluid>
                    <SliderProjects />
                </Container>
            </section>
            <GetInTouch />
            <MissionAndVision />
            <section className="counts section-bg">
                <Container fluid>
                    <Row>
                        <Col md={8} className='my-auto'>
                            <Container>
                                <Row>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-1">
                                            <span>10</span>
                                        </div>
                                        <h5>Years of Experience</h5>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-2">
                                            <span>05</span>
                                        </div>
                                        <h5>Lakh sq. ft. work</h5>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-1">
                                            <span>20</span>
                                        </div>
                                        <h5>Commercial Projects</h5>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-2">
                                            <span>20</span>
                                        </div>
                                        <h5>Satisfied Clients</h5>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={4} className='img-area'>
                            <img src={Design1} alt="" className="design-2" />
                            <div className="text-end">
                                <img src={Image2} alt="" className="img-fluid image-2" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <h2>Our <span>Projects</span></h2><br />
                    <p>Our ongoing projects</p>
                </Container>
                <Container fluid>
                    <SliderProjectsOngoing />
                </Container>
            </section>
            <ContactSection />
        </div>
    )
}

export default Homepage
