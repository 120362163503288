import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Value1 from '../assets/images/value-1.svg'
import Value2 from '../assets/images/value-2.svg'
import Vision1 from '../assets/images/vision-1.svg'
import Vision2 from '../assets/images/vision-2.svg'
import Commitment1 from '../assets/images/commitment-1.svg'
import Commitment2 from '../assets/images/commitment-2.svg'

export default function MissionAndVision() {
    return (
        <section className="mission">
            <Container>
                <div className="text-center">
                    <h2 className='center'>Our <span>Mission &amp; Vision</span></h2><br />
                    <p>What makes us the best choice</p>
                </div>
                <Row>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-1">
                            <table>
                                <tr>
                                    <td>
                                        <div className="icon-box">
                                            <img src={Commitment1} alt="Commitment" className="or-1" />
                                            <img src={Commitment2} alt="Commitment" className="hover-1" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <h3>Our Mission</h3>
                            <p>Our mission is to continuously improve all our business processes and productivity to achieve a level of excellence superior to our competitors and deliver the highest level of satisfaction to all customers and stakeholders.</p>
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-2">
                            <table>
                                <tr>
                                    <td>
                                        <div className="icon-box">
                                            <img src={Value1} alt="Values" className="or-2" />
                                            <img src={Value2} alt="Values" className="hover-2" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <h3>Our Values</h3>
                            <p>Conducting business is an atmosphere of transparency, fairness and mutual trust. In line with our philosophy of absolute emphasis on service quality, we aim to provide optimum levels of satisfaction to every Client.</p>
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-3">
                            <table>
                                <tr>
                                    <td>
                                        <div className="icon-box">
                                            <img src={Vision1} alt="Vision" className="or-3" />
                                            <img src={Vision2} alt="Vision" className="hover-3" />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <h3>Our Vision</h3>
                            <p>To be regarded as a leader in every sphere of our activity, we wish to become the 1st choice and trusted name for our customers and partners by providing world class constructions.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
