// Core
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from "react-router-dom";
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Logo from '../assets/images/logo/narnarayan-construction-logo-white.png';

const Footer = () => {
    let url = useLocation().pathname;
    const [urlHomepage, setUrlHomepage] = useState('')
    const [urlNcpl, setUrlNcpl] = useState('')
    const [urlContact, setUrlContact] = useState('')
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        navigateTo(url)
        // eslint-disable-next-line
    }, [])

    const navigateTo = (url) => {
        if (url === "/") {
            setUrlHomepage('active');
            setUrlNcpl('');
            setUrlContact('');
        } else if (url === "/ncpl") {
            setUrlHomepage('');
            setUrlNcpl('active');
            setUrlContact('');
        } else if (url === "/contact-us") {
            setUrlHomepage('');
            setUrlNcpl('');
            setUrlContact('active');
        }
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <div id="footer">
                <Container>
                    <Row>
                        <Col md={3}>
                            <img src={Logo} alt="Logo" className='logo' />
                            <p>Narnarayan Construction Private Limited established in 2020, earlier it was known as M/s. Narnarayan Construction.</p>
                            <div className="social">
                                <ul>
                                    <li>
                                        <a href="#!">
                                            <InstagramIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <FacebookIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <YouTubeIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!">
                                            <TwitterIcon />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={2}>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><h6><Link to="/">Home</Link></h6></li>
                                <li><h6><Link to="/business">Business</Link></h6></li>
                                <li><h6><Link to="/ncpl">NCPL</Link></h6></li>
                                <li><h6><Link to="/projects">Projects</Link></h6></li>
                                <li><h6><Link to="/contact-us">Contact Us</Link></h6></li>
                                <li><h6><Link to="/privacy-policy">Privacy Policy</Link></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} className='contact'>
                            <h5>Contact Us</h5>
                            <RoomIcon /><p> Sur. No. 158/2P, Nr. Akshaypatra <br /> Foundation, Opp. Shivparas Temple,<br />  At Post: Sukhpar, 370040 <br /> Ta. Bhuj - Kutch (Gujarat) </p>
                            <LocalPhoneIcon /><p><a href="tel:+91 99796 23144">+91 99796 23144</a></p>
                            <LocalPhoneIcon /><p><a href="tel:+91 96382 95900">+91 96382 95900</a></p>
                            <EmailIcon /><p><a href="mailto:info@narnarayanconstruction.com">info@narnarayanconstruction.com</a></p>
                        </Col>
                        <Col md={4}>
                            <h5>Google Map</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1541.8697080717259!2d69.6042923205454!3d23.199203880532746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSur.%20No.%20158%2F2P%2C%20%20Opp.%20Shivparas%20Temple%2C%20At%20Post%3A%20Sukhpar%2C%20370040%20Ta.%20Bhuj%20-%20Kutch%20(Gujarat)!5e0!3m2!1sen!2sin!4v1650109039525!5m2!1sen!2sin" title={'Map Location'} width="100%" height="200" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Col>
                    </Row>
                </Container>
                <div className="d-block d-sm-none menu-footer">
                    <Row className="p-3">
                        <Col className="b-right">
                            <Link to="/" className={urlHomepage} onClick={() => navigateTo('/')}>
                                Home
                            </Link>
                        </Col>
                        <Col className="b-right">
                            <Link to="/ncpl" className={urlNcpl} onClick={() => navigateTo('/ncpl')}>
                                NCPL
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/contact-us" className={urlContact} onClick={() => navigateTo('/contact-us')}>
                                Contact Us
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="copyright text-center">
                {/* eslint-disable-next-line */}
                <p>&copy; {currentYear} Narnarayan Construction Pvt. Ltd. All rights reserved.</p>
            </div>
            <div className="bottom"></div>
        </div>
    )
}

export default Footer
