import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vehicle3 from '../assets/images/vehicle-3.svg'
import Vehicle4 from '../assets/images/vehicle-4.svg'
import Vehicle5 from '../assets/images/vehicle-5.svg'
import Vehicle6 from '../assets/images/vehicle-6.svg'
import Vehicle7 from '../assets/images/vehicle-7.svg'
import Vehicle8 from '../assets/images/vehicle-8.svg'

const Machinery = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="text-center">
            <Slider {...settings}>
                <div className="machinery">
                    <img src={Vehicle3} alt="Tipper" className="img-fluid" />
                    <div className="count">
                        <h4><span>67</span> Tipper</h4>
                    </div>
                </div>
                <div className="machinery">
                    <img src={Vehicle4} alt="Transit Mixer" className="img-fluid" />
                    <div className="count">
                        <h4><span>11</span> Transit Mixer</h4>
                    </div>
                </div>
                <div className="machinery">
                    <img src={Vehicle5} alt="Front Wheel Loader" className="img-fluid" />
                    <div className="count">
                        <h4><span>3</span> Front Wheel Loader</h4>
                    </div>
                </div>
                <div className="machinery">
                    <img src={Vehicle6} alt="Excavator" className="img-fluid" />
                    <div className="count">
                        <h4><span>4</span> Excavator</h4>
                    </div>
                </div>
                <div className="machinery">
                    <img src={Vehicle7} alt="Tractor Loader / Trolley" className="img-fluid" />
                    <div className="count">
                        <h4><span>1</span> Tractor Loader / Trolley</h4>
                    </div>
                </div>
                <div className="machinery">
                    <img src={Vehicle8} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        <h4><span>4</span> JCB Backhoe Loader</h4>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default Machinery
