import React from 'react'
import { Button, Carousel } from 'react-bootstrap'
import Slider1 from '../assets/images/slider-1.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Slider = () => {
    return (
        <div>
            <div className="slider">
                <Carousel controls={false} interval={null} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider1} alt="" />
                        <Carousel.Caption>
                            <h1>Narnarayan Construction Pvt Ltd</h1>
                            <p>We Don't Construct Builing, We develop the Future</p>
                            <a href="#about" className='d-none d-sm-block'><Button variant="primary">Know more <ArrowForwardIcon fontSize='small' /></Button></a>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Slider
