const defaultTitle = 'Narnarayan Construction'
const defaultDescription = 'Narnarayan Construction'
const siteUrl = 'https://narnarayanconstruction.com/'

export const homepage = {
    title: defaultTitle,
    description: defaultDescription,
    canonical: siteUrl
}

export const business = {
    title: 'Business | Narnarayan Construction',
    description: 'Business | Narnarayan Construction',
    canonical: siteUrl + 'business'
}

export const ncpl = {
    title: 'NCPL | Narnarayan Construction',
    description: 'NCPL | Narnarayan Construction',
    canonical: siteUrl + 'ncpl'
}

export const projects = {
    title: 'Projects | Narnarayan Construction',
    description: 'Projects | Narnarayan Construction',
    canonical: siteUrl + 'projects'
}

export const contactUs = {
    title: 'Contact Us | Narnarayan Construction',
    description: 'Contact Us | Narnarayan Construction',
    canonical: siteUrl + 'contact-us'
}

export const privacyPolicy = {
    title: 'Privacy Policy | Narnarayan Construction',
    description: 'Privacy Policy | Narnarayan Construction',
    canonical: siteUrl + 'privacy-policy'
}

export const thankYou = {
    title: 'Thank You | Narnarayan Construction',
    description: 'Thank You | Narnarayan Construction',
    canonical: siteUrl + 'thank-you'
}
