import React, { useEffect } from 'react'
import { business } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import ContactSection from '../components/ContactSection'
import About from '../assets/images/about.png'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle1 from '../assets/images/vehicle-1.svg'
import MissionAndVision from '../components/MissionAndVision'
import Marquee from "react-fast-marquee";
import AboutNCPL from '../assets/images/about-ncpl.png'

const Business = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{business.title}</title>
                <meta name="description" content={business.description} />
                <link rel="canonical" href={business.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Narnarayan Construction Pvt. Ltd.</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Business</li>
                    </ul>
                </div>
            </section>
            <section id='about' className="entry section-bg bg-1">
                <Container>
                    <Row>
                        <Col md={6} className='text-center'>
                            <img src={About} alt="" className="img-fluid bg" />
                        </Col>
                        <Col md={6}>
                            <h2>About <span>Narnarayan Construction</span></h2>
                            <div className="content">
                                <p>Narnarayan Construction Private Limited established in 2020, earlier it was known as M/s. Narnarayan Construction (Proprietary/2013), has recently completed Seven years in the construction industry.</p>
                                <p>During the journey, we have successfully completed many projects e.g. commercial, residential, Temple, Trust Building, Commercial Building, Commercial Complex a healthcare buildings, education structures, pilgrimage sites etc. </p>
                                <p>We have also completed government structures and many projects under sub-contracting formula. It's time to grow and we are pretty sure about our future because we have strength, dedication and enthusiastic team.</p>
                                <p>As we know “the experience makes man perfect” and our skilled team and management made it possible to stand out in the competitive market. We are also specialized for our on-time resolution, lowest scrap generation, maximum output and execution of architectural development. That's true if you wish to design your future, you need to work on your efﬁciency. During the period we've worked on it to achieve many goals.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={6}>
                                <h2>About <span>NCPL</span></h2>
                                <div className="content">
                                    <p>Currently, we have equipment like Ready Mixed Concrete Batching Plant, portable concrete production machines, mixtures, Concrete pumps, Transit Mixture Trucks, Crain, etc. Also, we are ready to adopt future technologies to improve our strength. We are going to purchase new machinery e.g. Concrete Batching Plant, Pumps, Transit Mixtures Trucks, New centring materials as per requirement of upcoming projects.</p>
                                    <p>We are keeping ourselves upgraded with equipments and technologies to complete projects on-time.</p>
                                </div>
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={AboutNCPL} alt="About" className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
            </section>
            <MissionAndVision />
            <ContactSection />
        </div>
    )
}

export default Business
