export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'Business',
        path: '/business'
    },
    {
        label: 'NCPL',
        path: '/ncpl'
    },
    {
        label: 'Projects',
        path: '/projects'
    },
    {
        label: 'Contact Us',
        path: '/contact-us'
    }
]
