import React from 'react'
// Library
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Project1 from '../assets/images/projects/villa-at-naranpar-pasayati-kutch.jpg'
import Project2 from '../assets/images/projects/shree-sahajanand-gurukul-mankuva-kutch-4.jpg'
import Project3 from '../assets/images/projects/bhediya-bet-bsf-1.jpg'
import Project4 from '../assets/images/projects/shree-swaminarayan-temple-bhuj-kutch-2.jpg'
import Project5 from '../assets/images/projects/shree-sahajanand-gurukul-mankuva-kutch-3.jpg'
import Project6 from '../assets/images/projects/bhediya-bet-bsf-2.jpg'

const SliderProjects = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings} className='projects'>
                <div className="project-single">
                    <img src={Project1} alt="Project" className="img-fluid" />
                    <h4>SHREE SAHAJANAND GURUKUL - MANKUVA (KUTCH)</h4>
                </div>
                <div className="project-single">
                    <img src={Project2} alt="Project" className="img-fluid" />
                    <h4>BHEDIYA BET <br /> (BSF)</h4>
                </div>
                <div className="project-single">
                    <img src={Project3} alt="Project" className="img-fluid" />
                    <h4>"VILLA" AT NARANPAR (PASAYATI) KUTCH</h4>
                </div>
                <div className="project-single">
                    <img src={Project4} alt="Project" className="img-fluid" />
                    <h4>SHREE SAHAJANAND GURUKUL - MANKUVA (KUTCH)</h4>
                </div>
                <div className="project-single">
                    <img src={Project5} alt="Project" className="img-fluid" />
                    <h4>BHEDIYA BET <br /> (BSF)</h4>
                </div>
                <div className="project-single">
                    <img src={Project6} alt="Project" className="img-fluid" />
                    <h4>"VILLA" AT NARANPAR (PASAYATI) KUTCH</h4>
                </div>
            </Slider>
        </div>
    )
}

export default SliderProjects
