import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const GetInTouch = () => {
    return (
        <div>
            <section className="touch bg-2">
                <div className="bgc-2">
                    <Container>
                        <Row>
                            <Col md={9} className="my-auto">
                                <h2>Looking for a Quality &amp; Affordable <br /> Constructor for your Next Project?</h2>
                            </Col>
                            <Col md={3} className="text-center my-auto">
                                <Link to="/contact-us" className="btn btn-primary">Get In touch <ArrowRightAltIcon /></Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    )
}

export default GetInTouch
