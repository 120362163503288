import CostEffectiveness from './assets/images/cost-effectiveness.svg'
import DiversityOfSolution from './assets/images/diversity-of-solution.svg'
import CustomerService from './assets/images/customer-service.svg'
import Quality from './assets/images/quality.svg'
import LowerPollution from './assets/images/lower-pollution.svg'
import InventoryControl from './assets/images/inventory-control.svg'

export const benefits = [
    {
        icon: CostEffectiveness,
        name: 'Cost-Effectiveness',
        description: 'Ready mixed concrete is more cost effective because: Basic materials are now not stored on site and this reduces the need for storage space. Plant and machinery for mixing concrete are not required. Wastage of basic materials is avoided. Labor associated with production of concrete is reduced. Time required for the entire process is greatly reduced.'
    },
    {
        icon: Quality,
        name: 'Quality',
        description: 'RMC produced from fully automated batching plants guarantees quality since: The raw materials are subject to stringent quality and quantity measures. The concrete is subject to quality control throughout the manufacturing and delivery process.'
    },
    {
        icon: DiversityOfSolution,
        name: 'Diversity Of Solutions',
        description: 'A wide variety of ready mixed concrete can be produced, with ease and on demand, by varying the proportions/combinations of the cement, aggregates and admixtures.'
    },
    {
        icon: CustomerService,
        name: 'Customer Service',
        description: 'Ready mixed concrete provides customers with unique service quality due to: the availability of a large number of concrete mixer trucks which enable delivery rates to be kept under control. supply of special services for difficult worksites like pumps, conveyors, etc. the ability to adapt the pace of deliveries to the customer’s needs.'
    },
    {
        icon: LowerPollution,
        name: 'Lower Pollution',
        description: 'The use of RMC reduces air pollution in and around the worksite as the mixing is done at the plant.'
    },
    {
        icon: InventoryControl,
        name: 'Inventory Control',
        description: 'Ready Mixed Concrete provides customers with unique service quality'
    },
]