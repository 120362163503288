import React, { useState, useEffect } from 'react';
import { projects } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import GalleryImg2 from '../assets/images/gallery/bhediya-bet-bsf-1.jpg'
import GalleryImg3 from '../assets/images/gallery/building-constructed-in-ahmedabad-1.jpg'
import GalleryImg4 from '../assets/images/gallery/gallery-image-2.jpg'
import GalleryImg5 from '../assets/images/gallery/jv-business-park-at-bhuj-kutch.jpg'
import GalleryImg6 from '../assets/images/gallery/jv-business-park-at-bhuj-kutch-2.jpg'
import GalleryImg7 from '../assets/images/gallery/shree-sahajanand-gurukul-mankuva-kutch-1.jpg'
import GalleryImg8 from '../assets/images/gallery/shree-sahajanand-gurukul-mankuva-kutch-3.jpg'
import GalleryImg9 from '../assets/images/gallery/shree-swaminarayan-temple-bhuj-kutch-1.jpg'
import GalleryImg10 from '../assets/images/gallery/villa-at-naranpar-pasayati-kutch.jpg'
import GalleryImg11 from '../assets/images/gallery/vishranti-bhuvan-shree-swaminarayan-mandir-bhuj.jpg'
import AboutProjects from '../assets/images/about-projects.png'
import ContactSection from '../components/ContactSection'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
};

const tabImagesOnGoing = [GalleryImg3, GalleryImg4, GalleryImg5, GalleryImg6, GalleryImg7, GalleryImg8, GalleryImg9]
const tabImagesCompleted = [GalleryImg2, GalleryImg10, GalleryImg11]
const tabImagesAll = tabImagesOnGoing.concat(tabImagesCompleted);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Projects = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [value, setValue] = useState(0);
    const [finalImage, setFinalImage] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOpen = (image) => {
        setFinalImage(image)
        setOpen(true);
    }

    return (
        <div>
            <MetaTags>
                <title>{projects.title}</title>
                <meta name="description" content={projects.description} />
                <link rel="canonical" href={projects.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Commercial Projects</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Project</li>
                    </ul>
                </div>
            </section>
            <section className="entry section-bg">
                <Container>
                    <Row>
                        <Col md={6} className='text-center'>
                            <img src={AboutProjects} alt="" className="img-fluid bg" />
                        </Col>
                        <Col md={6}>
                            <h2>About <span>Our Projects</span></h2>
                            <div className="content">
                                <p>We are specialized for our on-time resolution, lowest scrap generation, maximum output and execution of architectural development.</p>
                                <p>We have successfully completed many projects e.g. commercial, residential, temple, trust building, commercial building, healthcare buildings, education structures, pilgrimage sites, etc. We have also completed government structures and many projects under sub-contracting formula. It's time to grow and we are pretty sure about our future because we have strength, dedication and enthusiastic team.</p>
                                <p>That's true if you wish to design your future, you need to work on your efficiency. During the period we've worked on it to achieve many goals.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="gallery">
                <Container>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Ongoing" {...a11yProps(1)} />
                                <Tab label="Completed" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Row>
                                {tabImagesAll.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Row>
                                {tabImagesOnGoing.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Row>
                                {tabImagesCompleted.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel>
                    </Box>
                </Container>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={finalImage} alt="Gallery" className="img-fluid" />
                </Box>
            </Modal>
            <ContactSection />
        </div>
    )
}

export default Projects
